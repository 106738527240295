import * as React from 'react';
import { useInView } from 'react-intersection-observer';

/* eslint-disable */
import cl from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { Image } from 'components/LazyImage';
import { SectionTitle } from 'components/SectionTitle';
import { fromScreen } from 'utils/media-query/responsive.util';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { ChevronLeft, ChevronRight } from '@dolstaff/shared/es/icons';
import { FreeMode, Navigation } from 'swiper';

const prefix = 'course-card';

export const ClassOfTeacher = props => {
  const { listCourse, teacherInfo } = props;
  const containerRef = React.useRef(null);
  const [reachEndSlider, setReachEndSlider] = React.useState(false);

  return (
    <Main ref={containerRef} id="other-classes">
      <SectionTitle content="Các lớp đang dạy" />
      <Wrapper>
        <>
          <div className={cl('navigation-action', `${prefix}`, 'next')}>
            <ChevronRight />
          </div>
          <div className={cl('navigation-action', `${prefix}`, 'prev')}>
            <ChevronLeft />
          </div>
        </>
        <Swiper
          pagination={{
            clickable: true
          }}
          navigation={{
            nextEl: `${prefix ? `.${prefix}` : ''}.next`,
            prevEl: `${prefix ? `.${prefix}` : ''}.prev`,
            disabledClass: 'disabled'
          }}
          modules={[Navigation, FreeMode]}
          spaceBetween={24}
          freeMode={{
            enabled: true,
            minimumVelocity: 0.05,
            momentum: true
          }}
          className={cl({ 'end-slider': reachEndSlider })}
          slidesPerView="auto"
          slidesPerGroup={1}
          onReachEnd={() => {
            setReachEndSlider(true);
          }}
        >
          {listCourse.map((clazz, idx) => {
            return (
              <SwiperSlide key={idx}>
                <Clazz clazz={clazz} teacherInfo={teacherInfo} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Wrapper>
    </Main>
  );
};

const routeLink = item => {
  if (item.classGroup.includes('TOEIC')) {
    return '/toeic';
  }
  if (item.classGroup.includes('SAT')) {
    return '/sat';
  }
  if (item.classGroup.includes('Tiếng Anh Giao Tiếp')) {
    return '/tieng-anh-giao-tiep';
  }

  if (item.classGroup.includes('IELTS Online')) {
    return '/hoc-ielts-online';
  }

  return item.slug;
};

const Clazz = props => {
  const { clazz, teacherInfo } = props;
  const { ref, inView } = useInView({
    threshold: 0.8
  });

  return (
    <ClassContainer ref={ref}>
      <Link to={routeLink(clazz)}>
        <LazyImage
          alt={clazz.levelDisplay || clazz.classGroup}
          ref={ref}
          handle={teacherInfo.listingImageHandler}
        />
      </Link>

      <ClassName>
        <Link to={routeLink(clazz)}>
          <Typography
            variant="semi-bold/16-24"
            desktopVariant="semi-bold/20-28"
            className="course-name"
            v3
            as="h3"
          >
            {clazz.levelDisplay || clazz.classGroup}
          </Typography>
        </Link>
        <Typography
          desktopVariant="regular/16-24"
          variant="regular/14-20"
          className="course-description"
          v3
        >
          {clazz.levelDescription || clazz.description}
        </Typography>
      </ClassName>
      <div>
        <Typography
          type="span"
          style={{ color: colorsV2.black80 }}
          variant="regular/14-20"
        >{`Giáo viên: `}</Typography>
        <Typography
          type="span"
          style={{ color: colorsV2.black80 }}
          variant="regular/14-20"
        >
          {teacherInfo?.displayName}
        </Typography>
      </div>
      <Typography
        variant="semi-bold/12-20"
        style={{ color: colorsV2.yellow100 }}
        className="rating"
      >
        <Image handle="1rv5msRHQpihJx9ujCrU" alt="rating" />
        {((teacherInfo?.feedbackScore || 0) / 2).toFixed(1)}
      </Typography>
    </ClassContainer>
  );
};

const NAVIGATION_BUTTON_SIZE = 40;

const Main = styled.div``;

const Wrapper = styled.div`
  position: relative;
  .swiper {
    position: relative;
    &:after {
      position: absolute;
      z-index: 4;
      content: '';
      right: -1px;
      top: 0;
      height: 100%;
      width: 113px;
      background: linear-gradient(
        270deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    &.end-slider {
      &:after {
        display: none;
      }
    }
  }
  .swiper-slide {
    width: fit-content !important;
    height: auto;
  }
  .navigation-action {
    display: none;
    color: ${colorsV2.black100};
    cursor: pointer;
    position: absolute;
    min-width: ${NAVIGATION_BUTTON_SIZE}px;
    min-height: ${NAVIGATION_BUTTON_SIZE}px;
    max-width: ${NAVIGATION_BUTTON_SIZE}px;
    max-height: ${NAVIGATION_BUTTON_SIZE}px;
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.32));
    place-items: center;
    border-radius: 50%;
    background-color: ${colorsV2.white100};
    font-size: 28px;
    line-height: 0;
    z-index: 2;

    ${fromScreen(776)} {
      display: grid;
      top: 25%;
    }

    &.next {
      right: -${NAVIGATION_BUTTON_SIZE / 2}px;
    }
    &.prev {
      left: -${NAVIGATION_BUTTON_SIZE / 2}px;
    }
    &.disabled {
      display: none;
    }
  }
`;

const ClassName = styled.div`
  display: grid;
  margin: 16px 0 4px 0;
`;

const ClassContainer = styled.div`
  background: ${colorsV2.white100};
  transition: 300ms;
  position: relative;
  width: 252px;

  .rating {
    position: absolute;
    top: 8px;
    right: 8px;
    background: ${colorsV2.yellow10};
    display: flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 4px;
  }

  img {
    width: 100%;
    border-radius: 8px;
  }

  &.blur {
    opacity: 0.5;
  }
  ${fromScreen(458)} {
    width: 344px;
    .course-name:hover {
      color: ${colorsV2.red100};
    }
  }
`;

ClassOfTeacher.propTypes = {};
