/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { LazyImage } from 'components/LazyImage';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { IconCourseArrowRight } from 'components/Icon/SVGIcons';
import { SectionTitle } from 'components/SectionTitle';
import Lightbox from 'react-image-lightbox';
import { useMounted } from 'utils/hooks/useMounted';

const Main = styled.div``;

const HoverState = styled.div`
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  position: absolute;
  background: rgb(0 0 0 / 40%);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: 0.2s ease;
  color: ${colorsV2.white100};
  cursor: pointer;

  .see-more {
    display: flex;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  &:hover {
    ${HoverState} {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const LastItem = styled.div`
  position: relative;
  ${HoverState} {
    visibility: visible;
    opacity: 1;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 158px);
  gap: 16px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  div {
    height: 100%;
    &:first-child {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    &:last-child {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  ${fromScreen(776)} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 194px);
    gap: 24px;
    div {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(6) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
      &:last-child {
        grid-area: auto;
      }
    }
  }

  ${fromScreen(1144)} {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 238px);
    div {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 3;
      }

      &:nth-child(3) {
        grid-column-start: 4;
        grid-column-end: 6;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &:nth-child(4) {
        grid-column-start: 3;
        grid-column-end: 5;
      }
      &:nth-child(7) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
      &:nth-child(2),
      &:nth-child(6) {
        grid-area: auto;
      }
    }
  }
`;

const StudentImage = ({ allImage }) => {
  const mounted = useMounted();
  const [isOpen, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const { isDesktop, isTablet } = useDeviceDetect3();
  let limitImage;
  if (mounted) {
    if (isDesktop) {
      limitImage = 9;
    } else if (isTablet) {
      limitImage = 7;
    } else {
      limitImage = 5;
    }
  }

  const onImageView = idx => {
    setOpen(true);
    setIndex(idx);
  };
  const data = allImage?.split(',');

  return (
    <Main>
      <SectionTitle content="Khoảnh khắc giữa <br/> giáo viên và học viên" />
      <ImageGrid>
        {data.slice(0, limitImage).map((img, idx, { length }) => {
          const lastItem = length - 1 === idx;
          if (!lastItem) {
            return (
              <ImageWrapper onClick={() => onImageView(idx)} key={idx}>
                <LazyImage handle={img} alt={`khoảnh khắc học viên ${idx}`} />
                <HoverState>
                  <Typography
                    variant="semi-bold/14-20"
                    className="see-more"
                    color={colorsV2.white100}
                  >
                    Xem chi tiết <IconCourseArrowRight />
                  </Typography>
                </HoverState>
              </ImageWrapper>
            );
          }
          return (
            <LastItem
              key={idx}
              onClick={() => onImageView(idx)}
              alt={`khoảnh khắc học viên ${idx}`}
            >
              <LazyImage handle={img} alt="Khoảnh khắc học viên" />
              <HoverState>
                <Typography
                  variant="semi-bold/14-20"
                  className="see-more"
                  color={colorsV2.white100}
                >
                  Xem thêm hình ảnh <IconCourseArrowRight />
                </Typography>
              </HoverState>
            </LastItem>
          );
        })}
      </ImageGrid>
      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 200000
            }
          }}
          mainSrc={getAssetLinkByHandle({
            handle: data[index]
          })}
          nextSrc={getAssetLinkByHandle({
            handle: data[(index + 1) % data.length]
          })}
          prevSrc={getAssetLinkByHandle({
            handle: data[(index + data.length - 1) % data.length]
          })}
          onCloseRequest={() => {
            setOpen(false);
            setIndex(0);
          }}
          onMovePrevRequest={() =>
            setIndex((index + data.length - 1) % data.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % data.length)}
        />
      )}
    </Main>
  );
};

export default StudentImage;
