import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import { SectionTitle } from 'components/SectionTitle';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { YoutubeVideo } from 'shared/Youtube/YoutubeVideo';
import OutlineArrowChevronDown from '@dolstaff/shared/es/icons/OutlineArrowChevronDown';
import OutlineArrowChevronUp from '@dolstaff/shared/es/icons/OutlineArrowChevronUp';

import { useMeasure } from 'react-use';
import cl from 'classnames';

const Main = styled.div`
  margin: 0 auto;

  .teacher-desc {
    margin-top: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .expand {
    .teacher-desc {
      max-height: unset;
      -webkit-line-clamp: unset;
    }
  }
  .see-more-btn {
    cursor: pointer;
    margin-top: 18px;
    text-align: center;
  }
  ${fromScreen(776)} {
    .teacher-desc {
      > span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  ${fromScreen(1144)} {
    max-width: 712px;
  }
`;

export const TeacherStyle = ({ teacherDescription, videoIntroduction }) => {
  const [expand, setExpand] = React.useState(false);
  const [ref, { height }] = useMeasure();
  const needExpand = height > 230;
  return (
    <Main>
      <SectionTitle content="Triết lý giáo dục" />
      {videoIntroduction && <YoutubeVideo src={videoIntroduction} />}
      <div ref={ref} className={cl({ expand })}>
        <div className="teacher-desc">
          {teacherDescription.split('\n').map(item => {
            return (
              <>
                <Typography variant="regular/14-20" as="span">
                  {item}
                </Typography>
                <br />
              </>
            );
          })}
        </div>
      </div>
      {needExpand && !expand && (
        <div className="see-more-btn" onClick={() => setExpand(true)}>
          <Typography
            variant="semi-bold/14-24"
            color={colorsV2.primary100}
            onlick
          >
            Xem thêm <OutlineArrowChevronDown />
          </Typography>
        </div>
      )}
      {expand && (
        <div className="see-more-btn" onClick={() => setExpand(false)}>
          <Typography
            variant="semi-bold/14-24"
            color={colorsV2.primary100}
            onlick
          >
            Thu gọn <OutlineArrowChevronUp />
          </Typography>
        </div>
      )}
    </Main>
  );
};
