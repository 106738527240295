import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import { shadows } from 'style/shadows';
import { fromScreen } from 'utils/media-query/responsive.util';
import { withCenterPageSection } from 'components/withCenterPageSection';

const Main = styled.div`
  display: grid;
  gap: 12px;

  margin: 32px 0;
  padding: 12px;
  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  .item {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    align-items: center;
    .content {
      display: flex;
      flex-wrap: wrap;
      > p:first-child {
        margin-right: 4px;
      }
    }
  }

  ${fromScreen(776)} {
    margin: 0;
    grid-template-columns: ${props => !props.oneColumn && '1fr 1fr'};

    gap: 40px;
    justify-content: center;
    border: none;
    border-radius: 20px;
    box-shadow: ${shadows.zIndex3};
    padding: 32px 56px;
    background: ${colorsV2.white100};
    .item {
      grid-template-columns: 32px 1fr;
      .content {
        .title {
          font-size: 20px;
          line-height: 24px;
        }
        .sub-text {
          font-size: 16px;
          line-height: 24px;
        }
        flex-direction: column;
      }
    }
  }

  ${fromScreen(1144)} {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    place-items: center;

    gap: 10px;
    padding: 40px 45px;
  }
`;

export const TeacherHighlight = withCenterPageSection(({ highlights }) => {
  return (
    <Main oneColumn={highlights.length === 1}>
      {highlights.map(item => (
        <div key={item.id} className="item">
          <LazyImage handle={item.iconHandler} alt="hightlight-giao-vien" />
          <div className="content">
            <Typography variant="semi-bold/14-20" className="title">
              {item.title}
            </Typography>
            <Typography
              variant="regular/14-20"
              className="sub-text"
              color={colorsV2.black80}
            >
              {item.subText}
            </Typography>
          </div>
        </div>
      ))}
    </Main>
  );
});
